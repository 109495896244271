<template>
  <nav class="pagination is-centered" role="navigation" aria-label="pagination">
    <a v-bind:disabled="!prev" class="pagination-previous" v-on:click="prev?$emit('prev'):''">Previous</a>
    <a v-bind:disabled="!next" class="pagination-next" v-on:click="next?$emit('next'):''">Next page</a>
    <ul class="pagination-list">
      <li v-for="(p,i) in pages" v-bind:key="i">
        <a v-if="p" class="pagination-link" v-bind:class="{ 'is-current': actual==p}" v-on:click="$emit('click', p)">{{p}}</a>
        <span v-if="!p" class="pagination-ellipsis">&hellip;</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'bpage',
  props: {
    prev: Boolean,
    next: Boolean,
    pages: Array,
    actual: Number,
  }
}
</script>

<style scoped>
.pagination-previous {
  background-color:white;
  border: 1px solid #D29500;
    color: #d29500;

}

.pagination-next {
  background-color:white;
  border: 1px solid #D29500;
  color: #d29500;
}
.pagination-link.is-current  {
  background-color: #d29500;
  border: 1px solid #D29500;
  color: #fff;
}
.pagination-link {
  background-color:white;
  border: 1px solid #D29500;
    color: #d29500;

}
</style>
