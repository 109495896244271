/* eslint-disable no-console */

import axios from 'axios'
import jwt from 'jsonwebtoken'
// import moment from 'moment';

const apiClient = axios.create({
  //  baseURL: `http://localhost:3000`,
  withCredentials: false, // This is the default
})

export default {
  secret: '',

  getKey(filename) {
    console.log('getkey:'+filename);

    this.secret='e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5';

    return new Promise(function(resolve) {
    resolve({data:`-----BEGIN RSA PRIVATE KEY-----
MIIJKQIBAAKCAgEAseu2rOcqo+/jiEP2jJWn0z+rqm9/RFaLkVgwv4gnlx6/ujmB
LeoEgAm7iVF46QpZhrpq/yTrQhjOvzSXbqgt/aNdBBaIb11QFA0d8Wi6Cc15Hmx2
qMtqxj9wq6kBLSsAULv77a9V4YFr1N8Jqtm5WQwIwEVz2pMcpmbeDos4iE9FJmo3
aahkiBNve0qFfNGuz7+YRNh55Ixh1RUZFyRzfbzzaJRn08vFAl9HWFihYm+YCwx+
XoE2+D9k/ZObiA2vytpRGW6tfNO4HdVg2dGtXdvnyQkXI3KumYYUFwshcrpYizTH
le3W8eiRzUfgkaaQuJVqKdbK3AWRC7MfeKZWtTFBdam603VBZTvGLV1o560JQRO2
oEVH8BMhkypT46SCXojon2bQSaTrJriXZFz/+gI9QS2NnsdBeTqTaN/Oe2qF+XZU
evi9t3Bs+h3CF20IUf4f79XoHaGeSY7h9tsqR8p9WRMlSugmCta1FcEFq3mP7kol
0+L5SRDyOXFBeZ6wi8UQuSaw1JfG2TokmLzlfDHb8+ta4PkrzJMTs7DOKSD4ZJuG
Xr5XTCX9/VdElkOqDh197963jkCHEiIDG+iEhp/6mtOoQXF3GsDj4RDwpnegIzMc
bqLcSRTDxTGHe1aqxb9bAbf1bv6IUT8D8lVtmZVMNKKWyle9uvJ53cChzjUCAwEA
AQKCAgAUAsnh+oH+PD3gFpzFRRPCG7goDDBJNF6wMzYQZLTnN0sjG9KeomJtMCsK
j8JEm6hkEdV06ucW4Qln0fdyN7Isg9IzYoFn3RvjlXIXs8qwzY5g0Jwr8e0JbRs0
A2cheGOpsOrt2bmUCmVhZNCGicRh8ouNbIJSRBeF064H/rqWAixp3Kps1Max01XH
jSMkXcUGkqsCm0JiZTOfrqbKxovCyZyq134yAQogcSzqD9Xe52Q3YelYobnYA/FM
3+wvSjLKYvGaJauXjYx4toYVGZMhKlmQb9hNYVmn5L+fs93INJM+3OnRwBVb0eeM
Khhk6VH/oLZ3DDR2uJ2Ork/M1xCLUkFSnY5mKVYMyLzzABqTR0aP9ReJ0whp5rPj
nqjGES0gbom24oSFWTPGtZY/GhllngeZXxPffS5kXChkAdwZNi9iE1CSkeiXFjoq
2cCb4v4UWGCamUEE8HgyYbDruZyX4HZgj5HPPnNHaTatMCDogTr021HS+Spx9d15
M1erJ5Y9e1dEZ4iYe4QNlZQJbSfujxIWVOI175q3k7I3ffRIOPeK66hoWE6wszWP
BM5lcdfksIOVPa61XggfHYMWx7CMsVhsJDvLrWpnJwLZvS+yyxVqWM9CPaMDtYzS
1DFq8QzYdoMaFiIKu7J/JoGExsT8AOuFk2iN5zHXI0uetkQhgQKCAQEA7NSWQtOp
UGuZUinqL5QHNn8cLSzFF2MTEaukc5d82R+ovvGtC2/nQbh9Fw7wzCsk8EckWs5K
O+8s3DZU42fDgzAmFyMY20yyk5Epr4K1gU5aT6VFj5SopGc2iPf/lbYzbwPMlbX4
IpRQpcOh/A11Q5eciO/G+yocoJTUZj5r1HSgl1vnroXr3ipRFzWA5P9QG9J+ARjw
RNPspdYikvwEa/LPHf0IzDY2TvKJ3GNIVAc8SdmEs6bbiExo8E6mzVgcCUW1iXiC
hsT8/AbMmCVGilHPWvzDZ6Mg/I6IR7ngEbKPIeh4OpPzKhxclEVaqFLl9t3TdUY3
yjlQlCMxXMBvFQKCAQEAwFJydLmtXZzMnVD5r+DGsrLgCqQmdKHV2N4l6qUJi1Xf
lOQbpMh6FVywmWhWGiOlZ6JusdlS6PWnGdBX8fp11gTKNqPBZjtBiynIKuOIRv/5
Dg9QORJ4GRB+YSz7UPkUxawmjqX+9SHUbjSA1b287SiOqay/8U+adHuSyiyb9/Xv
FTraVSi4cgwwALPgi544WI78dns3qmwdzW8wxIBYsLkuKNoD2jLVGwD0WluVIC2D
+ygFklsotXfrt1dwQGSzmKXFWXhe6e3MczYsQterixSOLxpjV6iqNf0HzznA1O+M
LdaKa1nh4Kx0uA7Pg13GRce5dwI+cZpIx3oQUHiqoQKCAQBh64/pN4yk9KgjAbOT
Ungc/zdOMlmXTt5+iuJm7LaxK1bhBBnRRcZvNPF7jDDhqeEO+8s7RFoLJ9v7fPdi
Qu/imRDWtis72R1oyDddGwUi3puWnLYDJKsJtqckwK1LYFcpyn9XUzKyUXJ650Vd
as8WUGRndgRmqsXFW2PqllqNMF+J1XUYgsTASezXVMbjZ/DeYAy7NW16d8k71Crd
bRgC7hTWyF1P2gZgZAW+OsqX+5ngyvAEO7Q/SaCF428yLgEBkHH70fjd8irLpaAI
NDlTTXoXm7TLb/Zeliwgw+iySIrHsU+soKmEus9Ieth+CjCM/rDEp3C/yyzW4yow
uFv1AoIBAQCs5THaN9ZgUwcr4lElyTg4DFEXn+yma5sZJNUHdij3Z1LhwpQLduMx
8yscLs1BIIyhd0h2gH5lyfnYXhHCCz0GEzZLGrpSErFgz0YRMI4h7hrThfbD6BOp
zMcgy1R/vfzrnFDpatejPi+Ah5jPDo0a05w1c4T3Bm4/i5wyPxpUN3chKdCAQGRY
uxYoe41BVxMmohza0vj/L8U3G1/HN+8DqOaTeTN/ZLVqWlhSJwUQcXq2PouLwIH/
afix0Vyrtxwx/c1GGs2RFeP4K1ptGQAxv1/3foySkKG8eWYc6LmvdE8cIkQ8MAi9
d6xSj5YEbjhCIURdddn3PRFyqwyhm2khAoIBAQDaNL5bzkRVx3h3R+CkuNvsrBVT
Mg6sEebgy31bYdZu0r4ytOFJjR7zAU0sHhB1VVKePp3tAqKNeoFt1R2PDvuvgRSr
7ecRRr1wxoCgJz/6AJUR/LjzjlTWUCTV0dnZ2L8s4C0XmUyLbIqD09qWb0x2UjDw
WUT7vxoOMykNsW4iOwu9QfF62tRmWmuSzkTiQXpmE2mM3KCzuDCb7KgWkCbAM4R6
Pg5mJ5/B4q9LKyV/Jd6liGKhBM3RvsyjULAR5G7toSZfR/iT0IDsK1whd9wYfDs8
n/Ev/zOXmrBn3FbcNjcCnpk0E7bn7bfXS3+juHpB2BcgF54b3UhLgKLlLfKP
-----END RSA PRIVATE KEY-----
`});
});

//    return axios.get(filename);
  },

  getBQ(con, sql) {
    var self = this;
    return new Promise(function(resolve, reject) {
        var url = "./bq";
        var payload = {
          token: con.mytoken,
          query: sql
        }
        var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });
        axios.post(url, { payload: jwtpayload } ).then(function(resp) {
          var data = resp.data;
          resolve(data);
        }).catch(function(err) {
          reject(err);
        });
    });
  },
  query(con, soql) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./query"; // state.connection.mytoken
      var payload = {
        token: con.mytoken,
        query: soql
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  gettemplateturni(con, period, from, to) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./gettemplateturni"; // state.connection.mytoken
      var payload = {
        token: con.mytoken,
        period: period,
        from: from,
        to: to
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  notify(con, email) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un notify');
      var url = "./notify"; 
      var payload = {
        token: con.mytoken,
        payload: email
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  createUser(con, user) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un createUser');
      var url = "./createuser"; 
      var payload = {
        token: con.mytoken,
        payload: user
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp.data);
        resolve(resp.data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  create(con, object, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un create');
      var url = "./create"; 
      var payload = {
        token: con.mytoken,
        payload: { object: object, body: body }
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  upsert(con, object, keyfield, id, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un upsert');
      var url = "./upsert"; 
      var payload = {
        token: con.mytoken,
        payload: { object: object, keyfield: keyfield, id: id, body: body }
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  update(con, object, id, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un update');
      var url = "./update"; 
      var payload = {
        token: con.mytoken,
        payload: { object: object, id: id, body: body }
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  delete(con, object, id) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un delete');
      var url = "./delete"; 
      var payload = {
        token: con.mytoken,
        payload: { object: object, id: id }
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  getTickets(con, companyId) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un attachment');
      var url = "./gettickets"; 
      var payload = {
        token: con.mytoken,
        companyId: companyId
      }

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });
      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  sendAttachment(con, body, cb) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un attachment');
      var url = "./attachment"; 
      var payload = {
        token: con.mytoken,
        payload: body
      }

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });
      var options= { };
      if (cb) { options.onUploadProgress=cb; }
      axios.post(url, { payload: jwtpayload }, options ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  newTask(con, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un task');
      var url = "./task"; 
      var payload = {
        token: con.mytoken,
        payload: body
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  newTicket(con, recordId, body) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda un ticket');
      var url = "./ticket"; 
      var payload = {
        token: con.mytoken,
        recordId: recordId,
        payload: body
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url,  { payload: jwtpayload }  ).then(function(resp) {
        console.log('ricevuto');
        console.log(resp);
        resolve(resp);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  
  login(con) {
    var self=this;
    return new Promise(function(resolve, reject) {
      console.log('login data:')
      console.log(con)

      con.myid_token = jwt.sign( {username:con.username, password:con.password }, self.secret );
      console.log(con.myid_token)

      var mytokenizedid = jwt.sign({
        email: con.username,  // id google
        id_token: con.myid_token // token google
      }, con.key, {
        expiresIn: "30s",
        algorithm: 'RS256'        
      });

      console.log(mytokenizedid)

      apiClient.post('/me', {
          token: mytokenizedid // jwt del mio payload
        })
        .then(function(resp) {
          var data = resp.data;
          console.log(data);
          var o = {
            mytokenizedid: mytokenizedid, // jwt del mio payload
            mytoken: data.token, // token del server
            userdata: data.userdata // dati utente
          }
          con.commit('SET_CONNECTION_TOKEN', o);
          resolve(o);
        })
        .catch(function(err) {
          console.log(err);
          reject(err);
        })
    })
  },
  getactivities(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./getactivities"; // state.connection.mytoken
      var payload = {
        token: con.mytoken,
        data: data
      }

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },
  setactivity(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./setactivity"; // state.connection.mytoken
      var payload = {
        token: con.mytoken,
        data: data
      }

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  }
}
