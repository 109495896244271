<template lang="html">

    <bmodal>
        <bcard>
            <template v-slot:header>
              <img src="/assets/timepaddy.png" alt="TimePaddy">
            </template>
            <template v-slot:content>

                <div class="field">
                    <label class="label">Username</label>
                    <div class="control has-icons-left has-icons-right">
                        <input v-model="username" class="input is-success" type="text" placeholder="Text input" value="bulma">
                        <span class="icon is-small is-left">
                            <i class="fas fa-user"></i>
                        </span>
                        <span class="icon is-small is-right">
                            <i class="fas fa-check"></i>
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Password</label>
                    <div class="control has-icons-left has-icons-right">
                        <input v-model="password" class="input is-success" type="text" placeholder="Text input" value="bulma">
                        <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                        </span>
                        <span class="icon is-small is-right">
                        <i class="fas fa-check"></i>
                        </span>
                    </div>
                </div>

            </template>
            <template v-slot:footer>
                    <a href="#" class="card-footer-item" v-on:click="submit">Save</a>
                    <a href="#" class="card-footer-item" v-on:click="close">Cancel</a>
            </template>
        </bcard>
    </bmodal>

</template>

<script>
import bmodal from '@/components/bmodal.vue'
import bcard from '@/components/bcard.vue'

export default {
  name: 'login',
  components: {
    bmodal,
    bcard
  },
  data: function() {
    return {
      show:false,
      username:'',
      password:'',
    }
  },
  mounted: function() {
    var self=this;
    this.$store.dispatch('getLocalUser').then(function(userdata) {
      if (userdata) {
        console.log('got localstorage');
        console.log(userdata);
        self.$emit('submit', {username:userdata.Username__c, password:userdata.Password__c});
      } else {
        self.show=true;
      }
    }).catch(function(err) {
        console.log(err);
        self.show=true;
    })
  },
  methods: {
    close: function() {
    },
    submit: function() {
      this.$emit('submit', {username:this.username, password:this.password});
    }
  }
}
</script>

<style scoped>
.boxlogin {
  max-width: 28rem;
}
.titolo {
  font-weight:700;
  font-size: 20px;
}
</style>
