<template>

    <bmodal v-if="show" v-on:cancel="$emit('cancel')" v-on:keydown="keyHandler">
      <bcard>
        <template v-slot:header>
          <p class="card-header-title">
            Turno Presenza
          </p>
        </template>
        <template v-slot:content>

          <div class="columns">
            <div class="column">
              <div class="box" v-bind:style="coloreTurno(localactivity.Mattina__c)">

                <div class="field">
                  <label class="label">Mattina</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="localactivity.Mattina__c">
                        <option value="Ufficio">U - Ufficio</option>
                        <option value="Remoto">R - Remoto</option>
                        <option value="Offline">_ - Offline</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Orario Inizio</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="Text input"  v-model="localactivity.Orario_inizio_Mattina__c">
                  </div>
                </div>
                <div class="field">
                  <label class="label">Orario Fine</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="Text input"  v-model="localactivity.Orario_fine_Mattina__c">
                  </div>
                </div>

              </div>
            </div>
            <div class="column">
              <div class="box" v-bind:style="coloreTurno(localactivity.Pomeriggio__c)">

                <div class="field">
                  <label class="label">Pomeriggio</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="localactivity.Pomeriggio__c">
                        <option value="Ufficio">U - Ufficio</option>
                        <option value="Remoto">R - Remoto</option>
                        <option value="Offline">_ - Offline</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Orario Inizio</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="Text input"  v-model="localactivity.Orario_inizio_Pomeriggio__c">
                  </div>
                </div>
                <div class="field">
                  <label class="label">Orario Fine</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="Text input"  v-model="localactivity.Orario_fine_Pomeriggio__c">
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Totale Ore</label>
            <div class="control">
              <input class="input" type="text" placeholder="Text input" v-model="localactivity.Ore__c">
            </div>
          </div>

          <div class="field is-grouped" style="justify-content: flex-end;">
            <div class="control">
              <button class="button is-link" v-on:click="submit">Submit</button>
            </div>
            <div class="control">
              <button class="button is-link is-light" v-on:click="$emit('cancel')">Cancel</button>
            </div>
          </div>

        </template>
      </bcard>
    </bmodal>

</template>

<script>
import bcard from "@/components/bcard.vue"
import bmodal from "@/components/bmodal.vue"

export default {
  name: 'turni',
  data: function() {
    return {
      activities: [],
      localactivity: {
        morning:'',
        evening:'',
        ore:'',
        turni: [],
      }
    }
  },
  components: {
    bcard,
    bmodal
  },
  watch: {
    'activity': 'setLocalActivity'
  },
  props: {
    show: Boolean,
    activity: {}
  },
  mounted: function() {
    this.getDefaultActivities();
  },
  methods: {
    coloreTurno: function(t) {
      var c=''
      switch (t) {
        case 'office':
          c='background-color: DARKSEAGREEN';
          break;
        case 'remote':
          c='background-color: yellow';
          break;
        case 'offline':
          c='background-color: lightgrey';
          break;
      }

      return c;
    },
    setLocalActivity: function() {
      // Object.assign(this.localactivity, this.activity);
      this.$set(this,'localactivity', this.activity);
    },
    fixDuration: function(activity) {
      // inizia per : -> minuti
      if (activity.duration.startsWith(':')) {
        activity.duration='00'+activity.duration;
        return;
      }
      // con :
      var i=activity.duration.search(':');
      if (i>=0) {
        console.log('caso complicato')
        if (i<2) {
          console.log('sistema le ore')
          activity.duration=("00"+activity.duration.substr(0,i)).substr(-2)+activity.duration.substr(i,3);
        }
        if (activity.duration.length<5) {
          console.log('minuti inconsistenti')
          activity.duration=activity.duration.substr(0,3)+"00"; // minuti inconsistenti
        }
        
        return;
      }
      var v=parseInt(activity.duration);
      // senza : e <10 -> ore
      if (v<10) {
        activity.duration="0"+activity.duration+":00";
        return;
      }      
      // >=10 -> minuti
      activity.duration="00:"+activity.duration;
      return;
    },
    submit: function() {
      this.$emit('submit', this.localactivity);      
    },
    getDefaultActivities: function() {
      this.activities.push( { id:1, label: 'pippo', duration:'' } );
      this.activities.push( { id:2, label: 'pluto', duration:'' } );
      this.activities.push( { id:3, label: 'paperino', duration:'' } );
      this.activities.push( { id:4, label: 'quattro', duration:'' } );
    },
    keyHandler: function() {
      alert('pippo')
    }
  }
}
</script>

<style scoped>

</style>
