<template>

    <bmodal v-if="show" v-on:cancel="$emit('cancel')" v-on:keydown="keyHandler">
      <bcard>
        <template v-slot:header>
          <p class="card-header-title">
            Aggiungi attività – {{ title }}
          </p>

        </template>
        <template v-slot:content>
          <div class="field is-grouped" style="justify-content: flex-end;">
            <div class="control">
              <button class="button isAlma" @click="submit">Submit</button>
            </div>
            <div class="control">
              <button class="button isAlmalight" @click="$emit('cancel')">Cancel</button>
            </div>
          </div>


        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Ricerca Attività</label>
              <div class="control">
                <input class="input" type="text" placeholder="Cerca attività" v-bind:value="searched" @keyup="searched=$event.target.value; search();">
              </div>
            </div>
            <div class="field">
              <label class="label">Ricerca Sottocommessa</label>
              <div class="control">
                <bautocomplete v-bind:placeholder="'cerca la sottocommessa'" v-bind:value="sottocommessa" v-on:change="sottocommessa=$event" v-on:submit="setSottocommessa"></bautocomplete>
              </div>
            </div>
            <div class="field">
              <label class="label">Note</label>
              <div class="control">
                <textarea class="textarea" placeholder="Note..." v-model="note" />
              </div>
            </div>
          </div>
          <div class="column">
            <table class="table">
              <thead>
                <tr>
                  <th>Durata</th>
                  <th>Attività</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="activity in activities" :key="activity.id">
                  <td>
                    <input class="input" type="text" v-model="activity.duration" size="6" placeholder="HH:MM" @change="fixDuration(activity)" style="height: 1.5em;">
                  </td>
                  <td v-bind:class="{ 'preferita': activity.preferred, 'team': activity.team}">{{activity.label}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

        </template>
      </bcard>

    </bmodal>

</template>

<script>
import bcard from "@/components/bcard.vue"
import bmodal from "@/components/bmodal.vue"
import bautocomplete from "@/components/bautocomplete.vue"

import apiclient from '@/apiclient.js'

export default {
  name: 'activities',
  data: function() {
    return {
      activities: [],
      timer:'',
      searched:'',
      sottocommessa:'',
      idsottocommessa:'',
      note: ''
    }
  },
  components: {
    bcard,
    bmodal,
    bautocomplete
  },
  props: {
    show: Boolean,
    day: Object
  },
  computed: {
    title: function() { 
      return this.day.format('DD/MM')
    }
  },
  watch: {
    show: function() {
      this.setDefault();
    }
  },
  mounted: function() {
    this.setDefault();
    this.getDefaultActivities()
  },
  methods: {
    setDefault: function() {
      console.log('setDefault')
      this.sottocommessa='';
      this.idsottocommessa='';
      this.note='';
    },
    setSottocommessa: function(e) {
      console.log('setSottocommessa:')
      console.log(e)
      this.sottocommessa=e.Name;
      this.idsottocommessa=e.Id;
    },
    search: function() {
      console.log('search: '+this.searched)
      if (this.timer) clearTimeout(this.timer);
      var self=this;
      setTimeout(function() {
        self.getActivities(self.searched).then(function(data) {
        console.log('getactivities: ');
        console.log(data)
        self.activities = []
        if (data.length>0) {
          data.forEach(function(d) {
            self.activities.push( { id: d.Id, label: d.Name, duration:'', team: false, preferred:false });
          })
        } 
      })
        self.found=true;
      }, 1000);
    },
    getActivities: function(s) {
      var self = this
      var soql="select Id, Name from TP_Attivita__c where Name LIKE '%"+s+"%'";
      return new Promise (function(resolve, reject) {
        apiclient.query(self.$store.getters.connection, soql).then(data => {
          console.log(data)
          return resolve(data)
        }).catch(e => reject(e))
      })
    },
    fixDuration: function(activity) {
      // inizia per : -> minuti
      if (activity.duration.startsWith(':')) {
        activity.duration='00'+activity.duration;
        return;
      }
      // con :
      var i=activity.duration.search(':');
      if (i>=0) {
        console.log('caso complicato')
        if (i<2) {
          console.log('sistema le ore')
          activity.duration=("00"+activity.duration.substr(0,i)).substr(-2)+activity.duration.substr(i,3);
        }
        if (activity.duration.length<5) {
          console.log('minuti inconsistenti')
          activity.duration=activity.duration.substr(0,3)+"00"; // minuti inconsistenti
        }
        
        return;
      }
      var v=parseInt(activity.duration);
      // senza : e <10 -> ore
      if (v<10) {
        activity.duration="0"+activity.duration+":00";
        return;
      }      
      // >=10 -> minuti
      activity.duration="00:"+activity.duration;
      return;
    },
    submit: function() {
      var self = this, acts=[];
      this.activities.forEach(function(a) {
        a.date = self.day.format('YYYY-MM-DD')
        a.note = self.note;
        a.idsottocommessa = self.idsottocommessa;
        if (a.duration) acts.push(a);
      })
      this.$emit('submit', acts);
      this.searched = ''
      this.note='';
      this.getDefaultActivities()
    },
    getDefaultActivities: function() {
      var self=this; // self.userid
      return new Promise (function(resolve) {
          self.activities = [];
          self.getPreferredActivities().then(function() {
            return self.getTeamActivities();
          }).then(function() {
            self.sortActivities();
            resolve();
          })
      });
    },
    sortActivities: function() {
      this.activities.sort(function(a,b) {
        if (a.label>b.label) return 1;
        if (a.label<b.label) return -1;
        return 0;
      })
    },
    getTeamActivities: function() {
      var self=this; // self.userid
      var soql="select Attivita__c, Attivita__r.Id, Attivita__r.Name from TP_Attivita_Team__c where Team__c='"+self.$store.getters.connection.userdata.Team__c.Id+"'";
      return new Promise (function(resolve, reject) {
        apiclient.query(self.$store.getters.connection, soql).then(data => {
          console.log(data)
          data.forEach(function(d) {
            self.activities.push( { id: d.Attivita__c, label: d.Attivita__r.Name, duration:'', team: true, preferred:false });
          })
          return resolve()
        }).catch(e => reject(e))
      })
    },
    getPreferredActivities: function() {
      var self=this; // self.userid
      var soql="select Attivita__c, Attivita__r.Id, Attivita__r.Name from TP_Attivita_Preferite__c where Persona__c='"+this.$store.getters.connection.userdata.AccountId+"'";
      return new Promise (function(resolve, reject) {
        apiclient.query(self.$store.getters.connection, soql).then(data => {
          console.log(data)
          data.forEach(function(d) {
            self.activities.push( { id: d.Attivita__c, label: d.Attivita__r.Name, duration:'', team: false, preferred:true });
          })
          return resolve()
        }).catch(e => reject(e))
      })
    },
    keyHandler: function() {
      alert('pippo')
    }
  }
}
</script>

<style scoped>
.isAlma {
  background-color: #314361;
  color: aliceblue;
}
.isAlmalight {
  background-color:white;
  color:#314361;
  border: 1px solid #314361;
}

.preferita {
  background-color:#EAFAF1;
}
.team {
  background-color:#F4F4F4;
}

</style>
