<template>

  <div class="field">
    <label class="label">{{label}}</label>
    <div class="control">
      <div class="dropdown" v-bind:class="{'is-active':matches.length>0}">
        <div class="dropdown-trigger" style="width: 100%;">
          <input type="text" class="input" v-bind:name="name" v-bind:value="value" v-bind:placeholder="placeholder"
            v-on:input="updateQuery($event)" v-on:keydown="handleKeyPress" />
        </div>
        <div v-if="matches.length>0" class="dropdown-menu">
          <div v-for="(match,index) in matches" v-bind:key="match.Id" class="dropdown-content" style="padding-bottom: 0px; padding-top: 0px;">
            <a class="dropdown-item" v-bind:class="{'is-active':index==activeIndex}" href="/" style="padding: 0px;"
              v-on:click.prevent="handleSelection(index)">
              {{match.Name}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import apiclient from '@/apiclient.js'

  export default {
    name: 'bautocomplete',
    props: {
      label: String,
      placeholder: String,
      name: String,
      value: String
    },
    data: function() {
      return {
        activeIndex:0,
        matches:[]
      }
    },
    methods: {
      doQuery: function () {
        console.log('doQuery')
        var self=this;
        return new Promise (function(resolve, reject) {
          apiclient.query(self.$store.getters.connection, "select Id, Name, Descrizione__c from Sotto_Commessa__c where ( Name like '%"+self.value+"%' or Descrizione__c like '%"+self.value+"%' ) and isTimePaddy__c=true order by Name limit 15").then(data => {
            console.log(data)
            self.matches=[];
            data.forEach(function(d) {
              self.matches.push({ Id: d.Id, Name: d.Name+' - '+d.Descrizione__c});
            })
            return resolve(self.matches)
          }).catch(e => reject(e))
        })
      },
      updateQuery: function (event) {
        console.log(event.target.value);
        this.$emit('change', event.target.value);
        if (this.timer) clearTimeout(this.timer)
        var self=this;
        this.timer=setTimeout(function() {
          self.doQuery();
        },300)
      },
      handleSelection: function (index) {
        this.$emit('submit', this.matches[index]);
        this.matches=[];
      },
      handleKeyPress: function (event) {
        switch (event.which) {
          case 13: // Enter key
            this.$emit('submit', this.matches[this.activeIndex]);
            this.matches=[];
            break;
          case 38: // Up arrow
          if (this.activeIndex) this.activeIndex--;
            break;
          case 40: // Down arrow
          if (this.activeIndex<(this.matches.length-1)) this.activeIndex++;
            break;
          default:
            break;
        }
      },

    }
  }
</script>

<style scoped>

</style>