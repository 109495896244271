<template>

<div class="card">
  <header class="card-header">
    <slot name="header"></slot>
  </header>
  <div class="card-content">
    <slot name="content"></slot>
  </div>
  <footer class="card-footer">
    <slot name="footer"></slot>
  </footer>
</div>

</template>

<script>
export default {
  name: 'bcard',
  props: {
  }
}
</script>

<style scoped>

</style>
