<template>
  <div class="table-container">
    <table class="table is-bordered is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th v-for="c in cols" :key="c.id" @click="$emit('colclick',c)">{{c.label}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="firstrow" @click="$emit('firstrowclick')">
          <slot name="first"></slot>
        </tr>
        <tr v-for="line in rows.slice(0, -1)" :key="line.index" @click="$emit('rowclick',line)">
<!--        <tr v-for="line in rows" :key="line.index" @click="$emit('rowclick',line)"> -->
          <td v-if="false">{{ user }}</td>
          <td v-for="el in line" :key="el.id" @click.stop="$emit('click', {r:line,c:el})" style="text-align:left">
            <h1 @click="passAct(el, line)">{{ el.tag }}</h1>
          </td>
        </tr>
        <tr v-show="lastrow" @click="$emit('lastrowclick')">
          <slot name="last"></slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'btable',
  props: {
    firstrow: Boolean,
    lastrow: Boolean,
    cols: Array,
    rows: Array,
    user: String
  },
  data: function() { return {}; },
  methods: {
    passAct(x) {
      this.$emit('pass', { content: x.activity })
    }
  }
}
</script>

<style scoped>
</style>
