import Vue from 'vue'
import Vuex from 'vuex'

import jwt from 'jsonwebtoken'
import apiclient from '@/apiclient.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logoimage:'',
    active_tab:'',
    tabs:[],
    mandati:[],

    login: false,
    auth: {},
    connection: {
      myid: '',
      myid_token: '',
      mytokenizedid: '',
      mytoken: '',
      userdata: {},
      zona: '',
    },
    // google payload
    identity: {},
    // google decoded user
    user: {
      id: '',
      id_token: '',
      name: '',
      imageUrl: '',
      email: '',
    },
    multizona: false,
    zone: [],
    position: {
      gps: false,
      lat: undefined,
      lng: undefined
    },
    devices: [],
    deviceback:'',
    map: {},
    campagne: [ ],
    objects: [],
    filtrocategoria: [true,true,true,true,true,true,true,true,true],
    filtrotipo:0,
    filtrotipi:'Both'
  },
  getters: {
    connection: function(state) { return state.connection },
    logged: function(state) { return state.login },
    whoami: function(state) { if (state.login) return state.connection.userdata.FirstName+' '+state.connection.userdata.LastName; else return ''; },
    auth: function(state) { return state.auth },
    identity: function(state) { return state.identity },
    map: function(state) { return state.map },
    position: function(state) { return state.position },
    zona: function(state) { return state.connection.zona },
    zone: function(state) { return state.zone },
    permissions: function(state) { 
      if (state.connection) {
        if (state.connection.userdata) {
          if (state.connection.userdata.SalesPaddy_Permissions__c) {
            return state.connection.userdata.SalesPaddy_Permissions__c.split(";");
          }
        }
      }
    },
    isAdmin: function(state, getters) {
      var p=getters.permissions;
      p ? p.indexOf("Admin")>=0 : false;
    }
  },
  mutations: {
    SET_CONNECTION: function(state, payload)        {
      state.connection = payload;
      if (payload.userdata && payload.userdata.agentcodes && payload.userdata.agentcodes.length>0) {
        state.connection.zona = payload.userdata.agentcodes[0];
        if (payload.userdata.agentcodes.length>1) state.multizona=true;
      }
    },
    SET_CONNECTION_TOKEN: function(state, payload)  {
      state.connection.mytoken = payload.mytoken;
      state.connection.mytokenizedid = payload.mytokenizedid;
      state.connection.userdata = payload.userdata;

      if (payload.userdata && payload.userdata.agentcodes && payload.userdata.agentcodes.length>0) {
        state.connection.zona = payload.userdata.agentcodes[0];
        if (payload.userdata.agentcodes.length>1) state.multizona=true;
      }
    },
    SET_USERDATA: function(state, payload)  {
      state.connection.userdata = payload.userdata;
    },
    SET_LOGIN: function(state, payload)             { state.login = payload; },
    SET_OBJ: function(state,payload ) { state.objects[payload.name]=payload.value }
  },
  actions: {
    getTeam: function(context) {
      var soql="select id, name, Team__r.id, Team__r.Name from TP_Persona_Team__c where Account__c='"+context.getters.connection.userdata
      return new Promise (function(resolve, reject) {
        apiclient.query(self.$store.getters.connection, soql).then(data => {
          console.log(data)
          self.activities = data;
          return resolve()
        }).catch(e => reject(e))
      })
    },
    getTemplateTurni: function(context, payload) {
      return new Promise(function(resolve, reject) {
        // query for 
        console.log('getTemplateTurni')
        console.log(payload)
        apiclient.gettemplateturni(context.getters.connection, payload.period, payload.from, payload.to).then(function(data) {
          console.log('records:'+data.length);
          resolve(data);
        }).catch(function(err){
          console.log(err);
          reject(err);
        })
      });
    },
    getLocalUser: function(context) {
      return new Promise(function(resolve, reject) {
        var userData=localStorage.getItem('timepaddy');
        apiclient.getKey();
        console.log(userData);
        console.log(apiclient.secret);
        if (userData) {
          jwt.verify(userData, apiclient.secret, function(err, data) {
            if (err) {
              console.log('bad localStorage: jwt broken');
              reject();
            } else {
              context.commit('SET_USERDATA', data);
              resolve(data);
            }
          });
        } else {
          reject()
        }
    });
  },
  setLocalUser: function(context, payload) {
    console.log('setLocalUser');
    console.log(payload);
    var jwtpayload = jwt.sign( payload, apiclient.secret);
    localStorage.setItem('timepaddy', jwtpayload); 
  },
  signOut: function(context) {
    localStorage.removeItem('timepaddy');
    context.commit('SET_LOGIN', false);
//      window.location.href=daviam.getLogoff(daviam.cfg, context.state.identity.id_token, window.location.hostname);
  }
},
modules: {
  }
})
