<template>

<nav class="navbar is-light" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="/">
        <h1 class="title">
        <img src="assets/logogrigio.png" alt="Alma Logo" data-retina="true">

          TimePaddy
          <ion-icon name="time-outline" style="color:#b6b3b3;"></ion-icon>
        </h1>
    </a>

    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class="navbar-menu">
    <!-- 
    <div class="navbar-start">
      <a class="navbar-item">
        Home
      </a>

      <a class="navbar-item">
        Attività
      </a>

    </div> -->

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-light" v-on:click="logout">
              <ion-icon name="person"></ion-icon>
            {{$store.getters.whoami}}
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

</template>

<script>
export default {
  name: 'bnavbar',
  props: {
  },
  data: function() {
    return  {
      showhamburger: false
    }
  },
  methods: {
    openhamburger: function() {

    },
    logout: function() {
      this.$store.dispatch('signOut');
    },
  }
}
</script>

<style scoped>
.title {
  font-size: 2.5rem;
  color:#b6b3b3;
}
</style>
