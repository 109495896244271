<template>
  <div id="app">
    <router-view v-if="$store.getters.logged"/>

    <div v-if="!$store.getters.logged">
      <loginplain v-on:submit="doLogin($event)"></loginplain>
    </div>

    <div v-if="loginerror">

      <article class="message">
        <div class="message-header">
          <p>User not enabled</p>
          <button class="delete" aria-label="delete"></button>
        </div>
        <div class="message-body">
          <p>Your user is not enabled for this application: contact support to verify your permissions.</p>
        </div>
      </article>

    </div>

  </div>
</template>

<script>
import apiclient from '@/apiclient.js'
// import objcfg from '@/objcfg.js'
import loginplain from '@/components/loginplain.vue'

export default {
  name: 'app',
  components: {
    loginplain
  },
  data: function() {
    return {
      loginerror: false,
    }
  },
  mounted: function() {
  },
  methods: {
    doLogin: function(event) {
      console.log('present myself:'+event.username);
      
      var connection={
        username: event.username,
        password: event.password,
        commit: this.$store.commit,
        userdata: {},
        zona: '',
        key:'',
       };

      var self=this;
      apiclient.getKey('priv.pem').then(function(key) {
        connection.key=key.data;
        apiclient.login(connection).then(function(resp) {
          console.log(resp);
          self.$store.commit('SET_CONNECTION', connection );
          self.$store.commit('SET_CONNECTION_TOKEN', resp );
          self.$store.commit('SET_LOGIN', true);

          self.$store.dispatch('setLocalUser',self.$store.getters.connection.userdata);

          if (self.$store.getters.connection.userdata) {
            if (self.$store.getters.connection.userdata.preferredLanguage__c) {
              console.log('set local2e='+self.$store.getters.connection.userdata.preferredLanguage__c);
              self.$i18n.locale=self.$store.getters.connection.userdata.preferredLanguage__c;
            }
          }
          self.loginerror=false;

          // eslint-disable-next-line no-undef
          gtag('set', {'user_id': JSON.stringify(connection.userdata.agentcodes)});
        }).catch(function(err){
          console.log('errore di autenticazione');
          console.log(err);
          self.loginerror=true;
        })        
      }).catch(function(err){
        console.log(err);
      })      
    }
  }
}
</script>

<style>

</style>
