<template>

  <div v-if="turno">
    <span v-for="h in 4" v-bind:key="'m'+h" v-bind:style="coloreTurno(turno.Mattina__c)" class="daybox has-tooltip-info" v-bind:data-tooltip="scrittaTurno(turno.Orario_inizio_Mattina__c, turno.Orario_fine_Mattina__c )">
      {{iconaTurno(turno.Mattina__c)}}
    </span>
    <span v-for="h in 4" v-bind:key="'e'+h" v-bind:style="coloreTurno(turno.Pomeriggio__c)" class="daybox has-tooltip-info" v-bind:data-tooltip="scrittaTurno(turno.Orario_inizio_Pomeriggio__c, turno.Orario_fine_Pomeriggio__c)">
      {{iconaTurno(turno.Pomeriggio__c)}}
    </span>
    <div v-if="turno.Ore__c">Ore: {{turno.Ore__c}} / {{formatDuration(turno.durationValue)}}</div>
  </div>

</template>

<script>
export default {
  name: 'mostraturno',
  props: {
    turno: Object
  },
  methods: {
    formatDuration: function(d) {
      return String(Math.floor(d))+':'+String( ( (d-Math.floor(d))*60 ).toFixed(0) );
    },
    scrittaTurno: function(from,to) {
      if (!from) return ' - ';
      return from+' '+to;
    },
    iconaTurno: function(t) {
      var c=''
      switch (t) {
        case 'Ufficio':
          c='U';
          break;
        case 'Remoto':
          c='R';
          break;
        case 'Offline':
          c='_';
          break;
      }

      return c;
    },
    coloreTurno: function(t) {
      var c=''
      switch (t) {
        case 'Ufficio':
          c='background-color: DARKSEAGREEN';
          break;
        case 'Remoto':
          c='background-color: yellow';
          break;
        case 'Offline':
          c='background-color: lightgrey';
          break;
      }

      return c;
    },    
  }
}
</script>

<style scoped>
.daybox {
  padding: 2px !important; 
  max-width:8px;
  width:8px;
  font-size: 8px;
}

</style>
