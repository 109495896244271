<template>

<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content" style="width:90%">
    <slot></slot>
  </div>
  <button class="modal-close is-large" aria-label="close" v-on:click="$emit('cancel')"></button>
</div>

</template>

<script>
export default {
  name: 'bmodal',
  props: {
    large: Boolean
  }
}
</script>

<style scoped>

</style>
