<template>

<div>
  <bnavbar class="bgAlma"></bnavbar>

  <section class="section bgpagina" >

    <div class="container">

      <nav class="level">
        <div class="level-left">
          <div class="level-item">
            <p class="subtitle">
              Settimana {{this.weekno}}
            </p>
           
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button btnOggi" @click="oggi">Oggi</button>
          </div>
          <div class="level-item">
            <button class="button" v-if="fullweek" @click="fullweek=false; setupCols()">Nascondi Fine settimana</button>
            <button class="button" v-if="!fullweek" @click="fullweek=true; setupCols()">Mostra Fine settimana</button>
          </div>
        </div>
      </nav>

      <btable style="text-align:center" :user="username" :cols="cols" :rows="rows" :firstrow="true" :lastrow="true" @pass="throwAct">

        <template v-slot:first>
          <td  v-for="(c,i) in cols" :key="c.id" @click.stop="$emit('firstclick')">
            <div v-if="i>=0" style="white-space: nowrap;" @click="turno=i; showturni=true">
              <mostraturno :turno="turni[i]"></mostraturno>
            </div>
            <div v-if="false">{{username}}</div>
          </td>
        </template>

        <template v-slot:last>
          <td v-for="(c,i) in cols" :key="c.id" @click.stop="$emit('lastclick', i)">
            <button v-if="weekno == today.isoWeek() && i>=0" class="button" @click="gotoIns(i)"><ion-icon name="add-outline"></ion-icon></button>
          </td>
        </template>

      </btable>

      <bpage :next="true" :prev="true" :pages="pages" :actual="weekno" @click="changeWeek" @next="nextWeek" @prev="prevWeek"></bpage>

    </div>

    <activity :act="oldAct" :show="showedit" @cancel="showedit=false" @submit="editActivity" @dodelete="deleteActivity"></activity>

    <activities :day="clickday" :show="showadd" @cancel="showadd=false" @submit="addActivities"></activities>

    <turni :show="showturni" @cancel="showturni=false" @submit="changeTurno($event); showturni=false" :activity="turni[turno]"></turni>

  </section>

</div>

</template>

<script>
// @ is an alias to /src
import bnavbar from "@/components/bnavbar.vue"
import btable from "@/components/btable.vue"
import bpage from "@/components/bpage.vue"
import moment from "moment/moment";

import activities from "@/components/activities.vue"
import mostraturno from "@/components/mostraturno.vue"
import turni from "@/components/turni.vue"

import activity from "@/components/activity.vue"

import apiclient from '@/apiclient.js'

export default {
  name: 'Home',
  components: {
    btable,
    bpage,
    bnavbar,
    activities,
    turni,
    mostraturno,
    activity
  },
  data: function() {
    return {
      showedit: false,
      showadd: false,
      showturni: false,
      date: {},
      datefrom: {},
      dateto: {},
      weekno: 0,
      today: new moment(),
      todaycol:-1,
      pages:[],
      cols:[
      ],
      rows:[
      ],
      records:[],
      fullweek: false,
      daynames: ['lun','mar','mer','gio','ven','sab','dom'],
      username: this.$store.getters.whoami,
      userid: this.$store.getters.connection.userdata.AccountId,
      turni:[
        /*
        { Mattina__c: 'Ufficio', Pomeriggio__c:'Ufficio', ore:8, Orario_inizio_Mattina__c:"08:30", Orario_fine_Mattina__c:"13:00", Orario_inizio_Pomeriggio__c:"14:00", Orario_fine_Pomeriggio__c:"17:30" },
        { Mattina__c: 'Remoto', Pomeriggio__c:'Offline', Ore__c:4, Orario_inizio_Mattina__c:"08:30", Orario_fine_Mattina__c:"13:00" },
        { Mattina__c: 'Ufficio', Pomeriggio__c:'Offline', Ore__c:4, Orario_inizio_Mattina__c:"08:30", Orario_fine_Mattina__c:"13:00" },
        { Mattina__c: 'Ufficio', Pomeriggio__c:'Ufficio', Ore__c:8, Orario_inizio_Mattina__c:"08:30", Orario_fine_Mattina__c:"13:00", Orario_inizio_Pomeriggio__c:"14:00", Orario_fine_Pomeriggio__c:"17:30"},
        { Mattina__c: 'Remoto', Pomeriggio__c:'Remoto', Ore__c:8, Orario_inizio_Mattina__c:"08:30", Orario_fine_Mattina__c:"13:00", Orario_inizio_Pomeriggio__c:"14:00", Orario_fine_Pomeriggio__c:"17:30"},
        { Mattina__c: 'Offline', Pomeriggio__c:'Offline' },
        { Mattina__c: 'Offline', Pomeriggio__c:'Offline' },
        */
      ],
      templateturni:[],
      turno: -1,
      oldAct: {},
      clickday: new moment()
    }
  },
  mounted: function() {
    window.addEventListener('keydown', this.keyHandler);
    this.oggi();
  },
  methods: {
    upsertData: function(turno) {
      var self=this;
      return new Promise(function(resolve, reject) {
        var t=self.turni[turno];
        var mydata={
          Template_Turni__c: self.templateturni.Id,
          Data_Modificata__c: self.oggi,
          Mattina__c: t.Mattina__c,
          Pomeriggio__c: t.Pomeriggio__c,
          Ore__c: t.Ore__c,
          Name: turno,
          Persona__c: self.userid
        };
        if (t.turni[0]) {
          mydata.Orario_inizio_Mattina__c=t.turni[0][0];
          mydata.Orario_fine_Mattina__c=t.turni[0][1];
        }
        if (t.turni[1]) {
          mydata.Orario_inizio_Pomeriggio__c=t.turni[1][0];
          mydata.Orario_fine_Pomeriggio__c=t.turni[1][1];
        }
        if (t.Id) {
          apiclient.create(self.$store.getters.connection, 'TP_Giornata_Template_Turni__c', mydata).then(function(data) {
            console.log('records:'+data.length);
            resolve(data);
          }).catch(function(err){
            console.log(err);
            reject(err);
          })
        } else {
          apiclient.update(self.$store.getters.connection, 'TP_Giornata_Template_Turni__c', t.Id, mydata).then(function(data) {
            console.log('records:'+data.length);
            resolve(data);
          }).catch(function(err){
            console.log(err);
            reject(err);
          })
        }
      })
    },
    setTurno: function(t,o) {
        t.Mattina__c=o.Mattina__c;
        t.Pomeriggio__c=o.Pomeriggio__c;
        t.Ore__c=o.Ore__c;
        t.turni=[[],[]]
    },
    setMyTurni: function(data) {
      
      this.templateturni=new Array(7);
      this.turni=new Array(7);
      data.Giornate_Template_Turni__r.records.forEach(function(t) {
        t.durationValue=0;
        if (!t.Account__c)
          this.templateturni[t.Name]=t;

        if (this.turni[t.Name] && this.turni[t.Name].Persona__c)
          console.log('skip');
        else
          this.$set(this.turni,t.Name,t);
      }, this);
    },
    gettemplateturni: function() {
      var self=this;
      return new Promise(function(resolve, reject) {
        var period=self.date.format('YYYY-MM-DD');
        var pfrom=self.datefrom.format('YYYY-MM-DD');
        var pto=self.dateto.format('YYYY-MM-DD');
        self.$store.dispatch('getTemplateTurni', {period:period, from:pfrom, to:pto} ).then(function(data) {
          console.log('gettemplateturni');
          console.log(data);
          if (data.length>0)
            self.setMyTurni(data[0]);
          resolve();
        }).catch(function(err) {
          reject(err);
        })
      })
    },
    oggi: function() {
      this.date=new moment().startOf('isoWeek');
      this.weekno=this.date.isoWeek(),
      this.changeDate();
    },
    changeDate: function() {
      this.setupCols();
      
      this.doPages();
      var self=this;
      this.readData().then(function() {
        self.setupRows();
      })

    },
    changeWeek: function(week) {
      console.log(week);
      this.date=new moment().isoWeek(week).startOf('isoWeek');
      console.log(this.date.format());
      this.weekno=week;
      this.changeDate();
    },
    nextWeek: function() {
      this.weekno++;
      this.date=new moment().isoWeek(this.weekno).startOf('isoWeek');
      this.changeDate();
    },
    prevWeek: function() {
      this.weekno--;
      this.date=new moment().isoWeek(this.weekno).startOf('isoWeek');
      this.changeDate();
    },
    changeTurno: function(activity) {
      console.log(activity);
      var t=this.turni[this.turno];
      t.Mattina__c=activity.Mattina__c;
      t.turni=activity.turni;
      t.Pomeriggio__c=activity.Pomeriggio__c;
      t.Ore__c=activity.Ore__c;
      this.upsertData(this.turno);
    },
    gotoIns: function(id) {
      var tmp = new moment(this.datefrom)
      console.log(this.datefrom)
      this.clickday=tmp.add(id, 'd');
      this.showadd=true
    },
    addActivities: function(activities) {
      console.log('addActivities')
      console.log(activities)
      this.showadd=false;
      this.clickday=new moment()
      var p=[];
      var self=this;
      activities.forEach(function(a) {
        console.log(a)
        p.push( self.insertActivity(a) );
      }, this)
      Promise.all(p).then(function() {
        self.changeDate()
      })
    },
    calcDuration: function(o) {
      return parseInt(o.substr(3,2))/60+parseInt(o.substr(0,2));
    },
    formatDuration: function(d) {
      return Math.floor(d)+':'+(d-Math.floor(d))*60;
    },
    insertActivity: function(a) {
      console.log('insertActivity')
      console.log(a)
      var self = this;
      return new Promise(function(res, rej) {
        var record = {
          Attivita__c: a.id,
          Data__c: a.date,
          Ora__c: a.duration,
          Persona__c: self.userid,
          Team__c: self.$store.getters.connection.userdata.Team__c.Id,
          Sotto_Commessa__c: a.idsottocommessa,
          Note__c: a.note
        };

        console.log(record);

        apiclient.create(self.$store.getters.connection, 'TP_Attivita_Svolta__c', record).then(function() {
          console.log('activity created')
          res();
        }).catch(function(e) {
          console.log(e);
          rej(e);
        })
      })
    },
    throwAct: function(o) {
      var act = o.content
      if(act.id) {
        this.oldAct = act
        console.log('selected activity: '+JSON.stringify(this.oldAct))
        this.showedit = true
      }
    },
    deleteActivity: function(activity) {
      console.log('deleteActivity')
      console.log(activity)
      this.showedit=false
      var self = this
      if (activity) {
        return new Promise (function(resolve, reject) {
          console.log(activity)
          apiclient.delete(self.$store.getters.connection, 'TP_Attivita_Svolta__c', activity.id).then(data => {
            console.log(data)
            self.changeDate()
            resolve(data)
          }).catch(e => reject(e))
        })
      }
    },
    editActivity: function(activity) {
      console.log(activity)
      this.showedit=false
      var self = this
      if (activity) {
        var a = {
          Ora__c: activity.duration,
          Note__c: activity.note
        }
        return new Promise (function(resolve, reject) {
          console.log(activity)
          apiclient.update(self.$store.getters.connection, 'TP_Attivita_Svolta__c', activity.id, a).then(data => {
            console.log(data)
            self.changeDate()
            resolve(data)
          }).catch(e => reject(e))
        })
      }
    },
    keyHandler: function(e) {
      var k = e.keyCode
      if (k==13) { // add new activity
        if (!this.showadd) this.showadd=true
      }
      if (k==27) { // add new activity
        if (this.showadd) this.showadd=false
      }
    },

    doPages: function() {
      var d=this.date.isoWeek();
      this.pages.splice(0,this.pages.length);
      this.pages.push(1);
      this.pages.push(0);
      this.pages.push(d-1);
      this.pages.push(d+0);
      this.pages.push(d+1);
      this.pages.push(0);
      this.pages.push(52);
    },
    setupCols: function() {
      this.cols.splice(0, this.cols.length);
      var colId=1;
      var d=moment(this.date);
      this.todaycol=-1
      this.datefrom=new moment(this.date);
      this.dateto=new moment(this.date).add(6,'day');
      // this.cols.push( { id: colId++, name: 'nome', label:'Settimana '+this.weekno } );
      this.cols.push( { id: colId++, name: 'lun', label:'Lunedì '+d.add(0,'day').format('DD/MM') } ); if (d.isSame(this.today, 'day')) this.todaycol=colId;
      this.cols.push( { id: colId++, name: 'mar', label:'Martedì '+d.add(1,'day').format('DD/MM')} );if (d.isSame(this.today, 'day')) this.todaycol=colId;
      this.cols.push( { id: colId++, name: 'mer', label:'Mercoledì '+d.add(1,'day').format('DD/MM')} );if (d.isSame(this.today, 'day')) this.todaycol=colId;
      this.cols.push( { id: colId++, name: 'gio', label:'Giovedì '+d.add(1,'day').format('DD/MM')} );if (d.isSame(this.today, 'day')) this.todaycol=colId;
      this.cols.push( { id: colId++, name: 'ven', label:'Venerdì '+d.add(1,'day').format('DD/MM')} );if (d.isSame(this.today, 'day')) this.todaycol=colId;
      if (this.fullweek) {
        this.cols.push( { id: colId++, name: 'sab', label:'Sabato '+d.add(1,'day').format('DD/MM')} );if (d.isSame(this.today, 'day')) this.todaycol=colId;
        this.cols.push( { id: colId++, name: 'dom', label:'Domenica '+d.add(1,'day').format('DD/MM')} );if (d.isSame(this.today, 'day')) this.todaycol=colId;
      }
      if (this.todaycol>=0) this.todaycol-=2;
    },
    getRecords: function() {
      this.records.splice(0, this.records.length);
      var self = this, from= self.datefrom.format('YYYY-MM-DD'), to = self.dateto.format('YYYY-MM-DD')
      return new Promise (function(resolve, reject) {
        apiclient.query(self.$store.getters.connection, `select Sotto_Commessa__c, Sotto_Commessa__r.Name, Attivita__c, Attivita__r.Name, Data__c, Ora__c, Note__c, Id from TP_Attivita_Svolta__c where Persona__c = '`+ self.userid +`' and Data__c >= `+ from +` and Data__c <= `+ to +` order by Data__c, SystemModstamp`).then(data => {
          console.log(data)
          data.forEach((d) => self.records.push(d))
          return resolve()
        }).catch(e => reject(e))
      })
    },
    emptyRow: function() {
      var arr = [], self = this, dn = this.daynames.slice(0, 5)
      dn.forEach(() => arr.push({ nome: self.username, tag: ''}))
      return arr
    },
    setupRows: function() {
      var self = this
      
      this.getRecords().then(function() {
        self.rows.splice(0, self.rows.length);
        self.rows[0] = self.emptyRow()
        self.records.forEach(r => self.setActivityRow(r))
        self.calcDurationSum();
      })
    },
    calcDurationSum: function() {
      console.log('calcDurationSum:'+this.turni.length);
      this.turni.forEach(function(t, c) {
        t.durationValue=0;
        this.rows.forEach(function(row) {
          if(row[c] && row[c].activity) {
            t.durationValue+=row[c].activity.durationValue
          }
        })
      }, this);
    },
    setActivityRow: function (activity) {
      var dtemp = new Date(activity.Data__c), date = moment(dtemp),
          col = date.diff(this.datefrom, 'days')

      var r = 0
      this.rows.forEach((row) => {
        if(row[col].tag != '') r++
      })

      if(r+1 >= this.rows.length) {
        var tmp = this.emptyRow()
        this.rows.push(tmp)
      }
      var row = this.rows[r], d = activity

      row[col] = {
        nome: this.username,
        tag: d.Ora__c + ' ' + d.Attivita__r.Name,
        activity: {
          durationValue: this.calcDuration(d.Ora__c),
          duration: d.Ora__c,
          id: d.Id,
          label: d.Attivita__r.Name,
          note: d.Note__c
        }
      }
      if (d.Sotto_Commessa__r) row[col].activity.sottocommessa=d.Sotto_Commessa__r.Name;
    },
    readData: function() {
      return this.gettemplateturni();
    }
  }
}
</script>

<style scoped>
.button {
  border-color: #314361;
}
.btnOggi {
  background-color:#314361;
  color:#fff;
}
.bgpagina {
  background-color: #fff;
}
.bgAlma {
    background-color:#314361;
}
@media screen and (min-width: 769px) {
.modal-card, .modal-content {
    margin: 0 auto;
    max-height: calc(100vh - 40px);
    width: 70%;
}}
</style>
