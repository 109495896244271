import { render, staticRenderFns } from "./activity.vue?vue&type=template&id=05de9660&scoped=true&"
import script from "./activity.vue?vue&type=script&lang=js&"
export * from "./activity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05de9660",
  null
  
)

export default component.exports