<template>

    <bmodal v-if="show" @cancel="$emit('cancel')" @keydown="keyHandler">
      <bcard>
        <template v-slot:header>
          <p class="card-header-title">
            Modifica attività
          </p>
        </template>
        <template v-slot:content>

        <div class="columns" > <!-- @load="getActivity" -->
          <div class="column" style="xxxxjustify-content: flex;">
            <div class="field">
              <label class="label">Attività</label>
              <div class="control">
                <input class="input" type="text" v-model="act.label" disabled>
              </div>
            </div>
            <div class="field">
              <label class="label">Sotto Commessa</label>
              <div>
                <textarea  class="input" v-model="act.sottocommessa" disabled/>
              </div>
            </div>
            <div class="field">
              <label class="label">Durata</label>
              <div>
                <input class="input" type="text" size="6" v-model="act.duration">
              </div>
            </div>
            <div class="field">
              <label class="label">Note</label>
              <div>
                <textarea  class="input" v-model="act.note" />
              </div>
            </div>
          </div>
        </div>

          <div class="field is-grouped" style="justify-content: space-between;">
            <div class="control">
              <button style="background-color: red;" class="button is-link" @click="doDelete">Delete</button>
            </div>
            <div class="field is-grouped" style="justify-content: flex-end;">
              <div class="control">
                <button class="button is-link" @click="submit">Submit</button>
              </div>
              <div class="control">
                <button class="button is-link is-light" @click="$emit('cancel')">Cancel</button>
              </div>
            </div>
          </div>

        </template>
      </bcard>

    </bmodal>

</template>

<script>
import bcard from "@/components/bcard.vue"
import bmodal from "@/components/bmodal.vue"

export default {
  name: 'activity',
  data: function() {
    return {
    }
  },
  components: {
    bcard,
    bmodal
  },
  props: {
    show: Boolean,
    act: Object
  },
  mounted: function() {
  },
  methods: {
    submit: function() {
      this.$emit('submit', this.act)
    },
    doDelete: function() {
      this.$emit('dodelete', this.act)
    },
    keyHandler: function() {
      alert('pippo')
    }
  }
}

// mock schema dato
/* 'TP_Attivita_Svolta__c',
{
  Attivita__c
  Data__c
  Ora__c
  Persona__c
  Team__c
  Note__c
} */

</script>

<style scoped>

</style>